<template>
  <settings-template :config="config" :search="$attrs.search">
    <!-- Carrier Account -->
    <template #slot1>
      <v-card data-cy="carrier-account-card">
        <v-card-title class="info">
          <h3 class="white--text">Carrier Accounts</h3>
          <v-spacer></v-spacer>
          <span class="white--text">Belonging to Company</span>
        </v-card-title>
        <v-card-text>
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="accountHeaders"
                :items="accounts"
                :rows-per-page-items="[5, 10, 15]"
                :loading="$apollo.queries.accounts.loading"
              >
                <template v-slot:actions-prepend> </template>
                <template v-slot:no-data>
                  <v-alert
                    color="warning"
                    v-if="!$apollo.queries.accounts.loading"
                    :value="true"
                  >
                    No shipping accounts? Add one!
                  </v-alert>
                  <v-alert color="info" v-else icon="sync" :value="true">
                    Loading your accounts, hold tight!
                  </v-alert>
                </template>
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.description }}</td>
                  <td>{{ props.item.number }}</td>
                  <td>{{ props.item.shipper.name }}</td>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="setDialogCarrierComponent()" color="primary" data-cy="new-carrier-account">
                Add Account
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
        <v-dialog v-model="dialog" persistent>
          <component :is="carrierComponent" @close="closeDialog" @success="addAccount"></component>
<!--          <add-shipping-account-->
<!--            data-cy="add-carrier-account"-->
<!--            @cancel="dialog = false"-->
<!--            @success="addAccount"-->
<!--          />-->
        </v-dialog>
      </v-card>
    </template>

    <!-- Carriers -->
    <template #slot0>
      <v-card data-cy="carrier-card">
        <v-card-title class="brown white--text">
          <h3>Carriers</h3>
          <v-spacer></v-spacer>
          <span>To manage services and accounts below</span>
        </v-card-title>
        <v-card-text>
          <carriers/>
        </v-card-text>
      </v-card>
    </template>

    <!-- Carrier Services -->
    <template #slot2>
      <v-card data-cy="carrier-service-card">
        <v-card-title class="orange lighten-3">
          <h3 class="white--text">Carrier Services</h3>
        </v-card-title>
        <v-card-text>
          <carrier-services/>
        </v-card-text>
      </v-card>
    </template>
  </settings-template>
</template>

<script>
import {
  GET_ALL_CARRIERS__ALIASED as CARRIERS,
  GET_CARRIER_ACCOUNTS as SHIPPING_ACCOUNTS
} from '@/api/graphql/Constants/Shipments'
import mutateCarrierAccounts from '../../api/graphql/mutationsJS/mutateCarrierAccounts'
import mutateAddresses from '../../api/graphql/mutationsJS/mutateAddresses'
import carriers from '@/components/settings/carriers'
import carrierServices from '@/components/settings/carrierServices'
import addShippingAccount from '@/components/dialogs/addShippingAccount'
import settingsTemplate from '@/views/settings/settingsTemplate'
import NewEasypostCarrierAccount from '@/views/settings/carriers/NewEasypostCarrierAccount-Generated.vue'
export default {
  name: 'logistics',
  mixins: [mutateCarrierAccounts, mutateAddresses],
  components: {
    carriers: carriers,
    'carrier-services': carrierServices,
    'add-shipping-account': addShippingAccount,
    'settings-template': settingsTemplate,
    'new-easypost-carrier-account': NewEasypostCarrierAccount
  },
  computed: {
    showDialog () {
      return this.dialog !== ''
    }
  },
  data () {
    return {
      config: {
        icons: {
          icon1: {
            icon: 'fal fa-user-circle',
            color: 'info'
          },
          icon0: {
            icon: 'fal fa-truck',
            color: 'brown'
          },
          icon2: {
            icon: 'fas fa-shipping-timed',
            color: 'orange lighten-3'
          }
        },
        slot0: 'Carrier Accounts',
        slot1: 'Carriers',
        slot2: 'Carrier Services'
      },

      // carrier accounts
      accountHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Reference', value: 'description' },
        { text: 'Account Number', value: 'number' },
        { text: 'Shipper', value: 'shipper.name' }
      ],

      accounts: [],
      loading: false,
      saving: false,

      // carriers
      carrierHeaders: [],
      savingCarrier: false,
      carrierComponent: '',
      carrierName: '',
      easypostCarriers: require('./carriers/carrierTypes.json'),

      // new data
      dialog: '',
      name: '',
      description: '',
      number: '',
      numberConfirm: '',

      carrier: 0,
      carriers: [],
      loadingCarriers: false,
      carrierConfig: {
        label: 'Carrier'
      },

      // rep: 0,
      // reps: [],
      // loadingReps: false,
      // repConfig: {
      //   label: 'For Rep?',
      //   placeholder: 'Optional'
      // },

      rules: {
        required: (e) => !!e || 'Required',
        match: (e) => e === this.number || 'Numbers must match',
        number: (e) => e > 0 || 'Must be a number'
      },

      color: '',
      message: ''
    }
  },
  apollo: {
    carriers: {
      query: CARRIERS
    },
    // reps: {
    //   query: GET_USERS__INPUT,
    //   update: (data) => data.users
    // },
    accounts: {
      query: SHIPPING_ACCOUNTS,
      variables: {
        input: {
          limit: 1000,
          filters: [{ key: 'client__isnull', value: true }]
        }
      },
      update: (data) => data.carrier_accounts
    }
  },
  methods: {
    setDialogCarrierComponent () {
      this.carrierComponent = 'new-easypost-carrier-account'
      this.dialog = true
    },

    closeDialog () {
      this.dialog = false
      this.carrierComponent = ''
    },

    clear () {
      this.name = ''
      this.description = ''
      this.number = ''
      this.numberConfirm = ''
      this.shipper = null
      this.dialog = ''
    },

    addAccount ({ value: account }) {
      this.accounts.push(account)
    }
  }
}
</script>
