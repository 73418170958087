import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'
import {
  Create__Shipping_Account__Input,
  CreateCarrierAccountMutation,
  CreateCarrierAccountMutationVariables,
  CreateEasypostFedExCarrierAccountMutation,
  CreateEasypostFedExCarrierAccountMutationVariables,
  CreateEasypostUpsCarrierAccountMutation,
  CreateEasypostUpsCarrierAccountMutationVariables,
  EasyPost_RegisterFedexAccountInput,
  EasyPost_RegisterUpsAccountInput,
  EasyPostCarrierAccount__FullFragment,
  GetEasypostCarrierAccountsQuery,
  Shipping_Account
} from '@/models/generated/graphql/ErpBackend'
import { useMutation, useQuery } from '@vue/apollo-composable'
import {
  CREATE_EASYPOST_FEDEX_CARRIER_ACCOUNT,
  CREATE_EASYPOST_UPS_CARRIER_ACCOUNT,
  GET_EASYPOST_CARRIER_ACCOUNTS
} from '@/api/graphql/Constants/EasyPost'
import { useEventHook } from '@/composition/UseEventHook'
import { CREATE_CARRIER_ACCOUNT } from '@/api/graphql/Constants/Shipments'
import { Sentry } from '@/lib/Sentry'
import { Severity } from '@sentry/types'

export interface UseCreateFedexOrUpsAccountParams {
  easypostInput: ComputedRef<EasyPost_RegisterFedexAccountInput | EasyPost_RegisterUpsAccountInput>
  tqsInput: ComputedRef<Create__Shipping_Account__Input>
}

export interface UseCreateFedexOrUpsAccountReturn {
  submit: (type: 'FedEx' | 'UPS') => void,
  loading: ComputedRef<boolean>
  onError: (fn: (params: Error) => void) => void
  onDone: (fn: (params: Shipping_Account) => void) => void
}

export function useCreateFedExOrUpsAccount (params: UseCreateFedexOrUpsAccountParams): UseCreateFedexOrUpsAccountReturn {
  /**
   * Used to kick off the query for easypost carriers
   */
  const getCarriers: Ref<boolean> = ref(false)
  /**
   * Stores the easypostId to be used by tqsInput: ComputedRef
   */
  const easypostId: Ref<string> = ref('')
  /**
   * onError result event hook
   */
  const errorResult = useEventHook()
  /**
   * onDone result event hook
   */
  const doneResult = useEventHook()

  /* Computed Input for TQS Carrier Account */
  const tqsInput: ComputedRef<Create__Shipping_Account__Input> = computed(() => ({
    ...params.tqsInput.value,
    easypost_id: easypostId.value
  }))

  const submit = async (type: 'FedEx' | 'UPS') => {
    Sentry.setContext('easypost_input', params.easypostInput.value)
    if (type === 'FedEx') {
      await createEasypostFedExAccount()
    } else {
      await createEasypostUpsAccount()
    }
  }

  /* Easypost Mutations */

  /**
   * Create FedEx Easypost Account mutation.
   * Uses onDone and onError event hooks
   */
  const {
    mutate: createEasypostFedExAccount,
    loading: creatingFedex,
    onDone: onFedExSuccess,
    onError: onFedExFailure
  } = useMutation<CreateEasypostFedExCarrierAccountMutation, CreateEasypostFedExCarrierAccountMutationVariables>(CREATE_EASYPOST_FEDEX_CARRIER_ACCOUNT,
    () => ({
      variables: {
        input: params.easypostInput.value as EasyPost_RegisterFedexAccountInput
      }
    }))
  onFedExSuccess(() => { getCarriers.value = true }) // successful response is empty so kick off query
  onFedExFailure(e => errorResult.trigger(e))

  /**
   * Create UPS Easypost Account mutation.
   * Uses onDone and onError event hooks
   */
  const {
    mutate: createEasypostUpsAccount,
    loading: creatingUps,
    onDone: onUpsSuccess,
    onError: onUpsFailure
  } = useMutation<CreateEasypostUpsCarrierAccountMutation, CreateEasypostUpsCarrierAccountMutationVariables>(CREATE_EASYPOST_UPS_CARRIER_ACCOUNT,
    () => ({
      variables: {
        input: params.easypostInput.value as EasyPost_RegisterUpsAccountInput
      }
    })
  )
  onUpsSuccess(() => { getCarriers.value = true }) // successful response is empty so kick off query
  onUpsFailure(error => errorResult.trigger(error))

  /* Easypost Carrier Accounts Query (To Do after Easypost Mutation) */

  /**
   * The query used to get all the easypost carrier accounts, it uses the
   * event hook to filter them out by the reference value and set the easypost
   * id of the account to be created in TQS database.
   */
  const {
    onResult,
    loading: querying
  } = useQuery<GetEasypostCarrierAccountsQuery>(GET_EASYPOST_CARRIER_ACCOUNTS,
    null,
    () => ({
      enabled: getCarriers.value, // should only be enabled once the reference object is set
      errorPolicy: 'ignore' // needed for now to handle weird resolver error when there shouldn't be one
    })
  )
  /**
   * This onResult hook find the EasyPost Carrier from the list of the carriers owned by the company.
   * The point is to grab the ID of the EasyPost Carrier and set the easypost_id of
   * params.tqsInput.easypost_id with it
   */
  onResult(async (result) => {
    const accounts: EasyPostCarrierAccount__FullFragment[] = result?.data?.EasyPost_ListCarrierAccounts ?? []
    if (accounts.length > 0) {
      const accountToFind: EasyPostCarrierAccount__FullFragment | undefined =
        accounts.find(a => a.credentials?.account_number === params.tqsInput.value.number)
      if (accountToFind) {
        easypostId.value = accountToFind.id
        Sentry.setContext('carrier_input', tqsInput.value)
        await createShippingAccount()
      }
    } else {
      errorResult.trigger(new Error('There were no carriers fetched from the integrator.'))
    }
  })

  const {
    mutate: createShippingAccount,
    loading: creatingAccount,
    onDone,
    onError
  } = useMutation<CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables>(CREATE_CARRIER_ACCOUNT,
    () => ({
      variables: {
        input: tqsInput.value
      }
    })
  )
  onDone(result => {
    const account = result.data?.Create__Shipping_Account ?? undefined
    if (account !== undefined) {
      doneResult.trigger(account)
    } else {
      Sentry.addBreadcrumb({ category: 'carrier_account', message: 'Got a result back but no account from TQS create', level: Severity.Error })
      const errors = result.errors
      if (errors && errors.length > 0) {
        const message = errors[0].message
        const messages = errors.map(e => e.message)
        Sentry.setContext('errors', { errors: messages })
        errorResult.trigger(new Error(message))
      } else {
        errorResult.trigger(new Error('Could not get error message, but a server error has occurred.'))
      }
    }
  })
  onError(e => {
    errorResult.trigger(e)
    Sentry.captureEvent(e)
  })

  /**
   * Since this composition function is a wrapper for more than one async
   * method this loading variable keeps track of the entire process's
   * loading state
   */
  const loading: ComputedRef<boolean> = computed(() => creatingFedex.value || creatingUps.value || querying.value || creatingAccount.value)

  return {
    submit,
    loading,
    onError: errorResult.on,
    onDone: doneResult.on
  }
}
