<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="carriers"
          item-key="id"
          :rows-per-page-items="[5, 10, 15]"
          :loading="loading"
        >
          <template v-slot:no-data>
            <v-alert color="warning" v-if="!loading" :value="true">
              No carriers? Add one!
            </v-alert>
            <v-alert color="info" v-else icon="sync" :value="true">
              Loading your carriers, hold tight!
            </v-alert>
          </template>
          <template v-slot:items="{ item }">
            <td>{{ item.title }}</td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <v-flex shrink>
            <v-btn color="info" data-cy="new-carrier" @click="dialog = true">
              Add
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialog" lazy width="40%">
      <v-card data-cy="new-carrier-card">
        <v-card-title class="brown">
          <h3 class="white--text">Add New Carrier</h3>
        </v-card-title>
        <v-container grid-list-xl>
          <v-layout row justify-center align-center>
            <v-flex shrink>
              <v-checkbox
                v-model="isCourier"
                label="Is a Courier"
                color="red"
                data-cy="is-courier"
              />
            </v-flex>
            <v-flex>
              <v-autocomplete
                v-if="!isCourier"
                v-model="carrierName"
                label="Carrier Name"
                data-cy="carrier-name-auto"
                :items="carriersToAddComputed"
              />
              <v-text-field
                v-else
                v-model="carrierName"
                label="Carrier Name"
                data-cy="carrier-name-text"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <t-alert :type="type" :message="message" />
          <v-spacer></v-spacer>
          <cancel-button :success="!!message" @click="cancel"/>
          <submit-button :disabled="!!message" :loading="saving" @click="saveNewCarrier"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { CreateCarrier, GET_ALL_CARRIERS__ALIASED as CARRIERS } from '@/api/graphql/Constants/Shipments'
import tAlert from '@/components/notifications/tAlert'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
export default {
  name: 'carriers',
  components: {
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  watch: {
    carrierName (value) {
      if (value.length > 0) {
        this.message = ''
      }
    }
  },
  computed: {
    carriersToAddComputed () {
      return this.carriersToAdd.filter((c) => {
        for (const ca of this.carriers) {
          if (ca.title.toLowerCase() === c.toLowerCase()) return false
        }
        return true
      })
    }
  },
  data () {
    return {
      carriers: [],
      carriersToAdd: [],
      headers: [
        { text: 'Carrier Name', value: 'title' }
      ],

      loading: false,
      saving: false,

      dialog: false,

      // new carrier information
      carrierName: '',
      isCourier: false,
      message: '',
      type: 'info'
    }
  },
  apollo: {
    carriers: {
      query: CARRIERS,
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    async saveNewCarrier () {
      if (this.carrierName) {
        this.saving = true
        try {
          const newCarrier = await CreateCarrier({ name: this.carrierName, is_easypost: this.isCourier === false, is_courier: this.isCourier })
          this.carriers.push({ id: newCarrier.id, title: newCarrier.name })
          this.message = 'Success!'
          this.type = 'success'
        } catch (error) {
          this.message = error.message
          this.type = 'error'
        } finally {
          this.saving = false
        }
      } else {
        this.message = 'Type a carrier name'
        this.type = 'error'
      }
    },

    cancel () {
      this.dialog = false
      this.carrierName = ''
      this.type = 'info'
      this.message = ''
    }
  },
  beforeMount () {
    const json = require('./service_levels.json')
    this.carriersToAdd = Object.keys(json).filter(key => json[key]?.length > 0)
  }
}
</script>

<style scoped>
</style>
