



















import { computed, defineComponent, Ref, ref } from '@vue/composition-api'
import { CapIt } from '@/lib/helpers'
import EasypostCarrierSelect from '@/views/settings/carriers/EasypostCarrierSelect.vue'
import { EasypostCarrierType } from '@/composition/UseEasyPostCarrierInputs'
import EasypostCarrierFieldsInput from '@/views/settings/carriers/EasypostCarrierFieldsInput.vue'
import NewFedExOrUPSEasypostAccount from '@/views/settings/carriers/NewFedExOrUPSEasypostAccount.vue'

export default defineComponent({
  name: 'NewEasypostCarrierAccount',
  components: {
    'easypost-carrier-select': EasypostCarrierSelect,
    'easypost-carrier-fields-input': EasypostCarrierFieldsInput,
    'new-fedex-or-ups-easypost-account': NewFedExOrUPSEasypostAccount
  },
  setup (_, { emit }) {
    // @ts-ignore
    const carrierType: Ref<EasypostCarrierType> = ref()
    const carrierId: Ref<string> = ref('')
    const carrierName = computed(() => carrierType?.value?.readable ?? '')
    const notFedExOrUps = computed(() => {
      return !(carrierName.value === 'FedEx' || carrierName.value === 'UPS')
    })

    interface CarrierSelectEvent {
      value: EasypostCarrierType,
      id: string
    }
    const setCarrier = (event: CarrierSelectEvent) => {
      carrierType.value = event.value
      carrierId.value = event.id
    }

    return {
      setCarrier,
      carrierId,
      notFedExOrUps,
      emit,
      carrierType,
      CapIt,
      carrierName
    }
  }
})
