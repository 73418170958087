import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'
import { EasyPost_MakeCarrierAccountInput } from '@/models/generated/graphql/ErpBackend'
import { FieldParent } from '@/composition/UseGeneratedInputs'

// created easypost account id: ca_a5d354ba93f540d19ce2fefc5ce78365 fedex
// created easypost account id: ca_5e01b54261f443149504602e7cb742c5 ups

export interface EasypostCarrierType {
  fields: EasyPostFieldsObject
  type: string,
  readable: string
}

export interface EasyPostCredentialsObject {
  [key: string]: EasyPostInputField
}

export interface EasyPostFieldsObject {
  auto_link: boolean,
  custom_workflow: boolean,
  credentials: EasyPostCredentialsObject
  creation_fields?: EasyPostFieldsObject
}

interface EasyPostInputField {
  label: string
  visibility: 'visible' | 'checkbox' | 'fake' | 'password' | 'masked' | 'invisible'
}

interface TraqsysEasyPostInputField extends EasyPostInputField {
  field: string
  value: string | boolean
  group: string
  hint?: string
}

export interface UseEasyPostCarrierInputsReturn {
  fields: Ref<TraqsysEasyPostInputField[]>
  input: ComputedRef<EasyPost_MakeCarrierAccountInput>
  groups: Ref<FieldParent[]>,
  filter: (name: string) => TraqsysEasyPostInputField[]
}

export function useEasyPostCarrierInputs (carrierType: EasypostCarrierType) {
  const mapIt = (carrierType: EasypostCarrierType): TraqsysEasyPostInputField[] => {
    const arr: TraqsysEasyPostInputField[] = []
    if (carrierType.fields.credentials) {
      const group = 'credential'
      groups.value.push({ name: group, link: '', linked: false })
      for (const key in carrierType.fields.credentials) {
        const object = carrierType.fields.credentials[key]
        if (object.visibility !== 'invisible' && !arr.find(f => f.field === key)) {
          arr.push({
            visibility: object.visibility,
            label: object.label.includes('(') ? object.label.split('(')[0].trim() : object.label,
            field: key,
            value: '',
            group: group,
            hint: object.label.includes('(') ? `(${object.label.split('(')[1]}` : ''
          })
        }
      }
    }
    const hasCreationFields = carrierType.fields.creation_fields !== undefined
    // if it has creation fields
    if (hasCreationFields) {
      for (const keyi in carrierType.fields.creation_fields) {
        const link = keyi.includes('shipping') ? 'company_information' : ''
        const groupName = keyi.split('_')[0]
        if (groups.value.map(g => g?.name).indexOf(groupName) === -1) groups.value.push({ name: groupName, link: link, linked: false })
        // @ts-ignore
        for (const keyf in carrierType.fields.creation_fields[keyi]) {
          // @ts-ignore
          const field = carrierType.fields.creation_fields[keyi][keyf]
          if (field.visibility !== 'invisible' && !arr.find(f => f.field === keyf)) {
            arr.push({
              group: groupName,
              field: keyf,
              value: '',
              ...field
            })
          } else {
          }
        }
      }
    }
    return arr
  }
  const groups: Ref<FieldParent[]> = ref([])
  const fields: Ref<TraqsysEasyPostInputField[]> = ref([])
  const filter: (groupName: string) => TraqsysEasyPostInputField[] = (groupName: string) => {
    return fields.value.filter(f => f.group === groupName)
  }
  fields.value = mapIt(carrierType)
  const input: ComputedRef<EasyPost_MakeCarrierAccountInput> = computed(() => {
    const x: EasyPost_MakeCarrierAccountInput = { credentials: [], type: carrierType.type }

    for (const field of fields.value) {
      if (field.group === 'meta') {
        // @ts-ignore
        x[field.field] = field.value
      } else {
        // @ts-ignore
        x.credentials.push({ key: field.field, value: field.value })
      }
    }
    return x
  })

  const ready: ComputedRef<boolean> = computed(() => {
    for (const key in input.value) {
      // @ts-ignore
      if (input.value[key] === '') return false
    }
    return true
  })
  return { fields, groups, filter, input, ready }
}
