import {
  CarriersQuery,
  Create__Shipping_Account__Input,
  CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables,
  CreateEasypostCarrierAccountMutation, CreateEasypostCarrierAccountMutationVariables,
  EasyPost_MakeCarrierAccountInput
} from '@/models/generated/graphql/ErpBackend'
import { computed, ComputedRef, ref, Ref } from '@vue/composition-api'
import { useEventHook } from '@/composition/UseEventHook'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { CREATE_EASYPOST_CARRIER_ACCOUNT } from '@/api/graphql/Constants/EasyPost'
import { CREATE_CARRIER_ACCOUNT, GET_ALL_CARRIERS } from '@/api/graphql/Constants/Shipments'

export function useCreateCompanyCarrierAccount (input: ComputedRef<EasyPost_MakeCarrierAccountInput>) {
  // externals
  const loading: Ref<boolean> = ref(false)
  const successEvent = useEventHook()
  const errorEvent = useEventHook()

  // internal
  const easypostCarrierAccountId: Ref<string> = ref('')
  const shipperId: Ref<string> = ref('')
  const accountInput: ComputedRef<Create__Shipping_Account__Input> = computed(() => ({
    easypost_id: easypostCarrierAccountId.value,
    shipper_id: shipperId.value,
    name: input.value.readable,
    number: input.value.credentials?.find(c => c.key === 'account_number')?.value ?? '',
    description: input.value.reference
  }))

  /* Query for Carriers */
  const {
    onResult,
    onError: onQueryFail
  } = useQuery<CarriersQuery>(GET_ALL_CARRIERS, null, () => ({ enabled: !!input.value.type && !shipperId.value }))
  onResult((result) => {
    const carriers: { name: string, id: string, is_courier: boolean }[] = result.data.shipping_carriers ?? []
    const mapped = carriers.map(c => ({ name: c.name.toLowerCase().replace(/\s/g, ''), id: c.id }))
    const carrier = mapped.find(c => c.name === input.value.type?.toLowerCase().replace('account', ''))
    if (carrier) {
      shipperId.value = carrier.id
    } else {
      errorEvent.trigger(new Error('Not able to find a suitable carrier id for this account'))
    }
  })
  onQueryFail((e) => {
    errorEvent.trigger(e)
  })

  /* Create the Easypost Carrier Account */
  const {
    mutate: createEasypostAccount,
    onDone: onEasypostSuccess,
    onError: onEasypostError
  } = useMutation<CreateEasypostCarrierAccountMutation, CreateEasypostCarrierAccountMutationVariables>(CREATE_EASYPOST_CARRIER_ACCOUNT,
    () => ({
      variables: {
        input: input.value
      }
    })
  )
  onEasypostSuccess((result) => {
    const id = result.data?.EasyPost_MakeCarrierAccount?.id ?? ''
    if (id) {
      easypostCarrierAccountId.value = id
      saveToDb()
    }
  })
  onEasypostError((e) => {
    errorEvent.trigger(e)
  })

  const {
    mutate: saveToDb,
    onDone,
    onError
  } = useMutation<CreateCarrierAccountMutation, CreateCarrierAccountMutationVariables>(CREATE_CARRIER_ACCOUNT,
    () => ({
      variables: {
        input: accountInput.value
      }
    })
  )
  onDone((result) => {
    successEvent.trigger(result.data!.Create__Shipping_Account)
  })
  onError((e) => {
    errorEvent.trigger(e)
  })

  const start = () => {
    loading.value = true
    createEasypostAccount()
  }

  return {
    loading,
    mutate: start,
    onDone: successEvent.on,
    onError: errorEvent.on
  }
}
