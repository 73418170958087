import { computed, ComputedRef, Ref, ref } from '@vue/composition-api'
import { GetInputFieldsAndDescriptions } from '@/api/graphql/Constants/SchemaIntrospection'
import {
  EasyPost_MakeCarrierAccountInput,
  EasyPost_RegisterFedexAccountInput,
  EasyPost_RegisterUpsAccountInput
} from '@/models/generated/graphql/ErpBackend'
import { TAlertObject } from '@/composition/UseTAlert'

export type SupportedGeneratedInputTypeStrings = 'EasyPost_RegisterFedexAccountInput' | 'EasyPost_RegisterUPSAccountInput'
export type SupportedGeneratedInputTypes = EasyPost_RegisterFedexAccountInput | EasyPost_RegisterUpsAccountInput

export interface UseGeneratedInputsReturn <T> {
  input: ComputedRef<T>
  ready: ComputedRef<boolean>
  error: Ref<string>
  fields: Ref<GeneratedInputField[]>
  filter: (name: string) => GeneratedInputField[]
  loading: Ref<boolean>
  parents: Ref<FieldParent[]>
}

export interface FieldParent {
  name: string
  link?: string
  linked?: boolean
}

interface GeneratedInputField {
  field: string
  value: string
  label: string
  optional: boolean,
  hint?: string
  placeholder?: string
  parent: string
  child: string
  link: string
}

/**
 * For use only when the descriptions in the sdl are setup to be JSON parsed
 * @param type
 * @param alert the alert object used to send messages, optional
 */
export function useGeneratedInputs <T> (type: SupportedGeneratedInputTypeStrings, alert: TAlertObject): UseGeneratedInputsReturn<T> {
  // variables
  const fields: Ref<GeneratedInputField[]> = ref([])
  const loading: Ref<boolean> = ref(false)
  const parents: Ref<FieldParent[]> = ref([])
  const error: Ref<string> = ref('')

  const filteredFieldsByParent = (parent: string): GeneratedInputField[] => {
    return fields.value.filter(f => f.parent === parent)
  }

  loading.value = true
  GetInputFieldsAndDescriptions(type)
    .then(response => {
      fields.value.unshift(...response?.map((f) => {
        const parsed = (f.description && f.description.includes('}')) ? JSON.parse(f.description) : { parent: '', link: '' }
        const parent = parsed?.parent ?? ''
        const link = parsed?.link ?? ''
        if (parents.value.map(p => p.name).indexOf(parent) === -1) parents.value.push({ name: parent, link: link || '', linked: false })
        return {
          field: f.name,
          value: '',
          ...parsed,
          optional: f.name.includes('invoice') // right now only optional fields are ones on UPS about invoice details
        }
      })) // end unshift
    })
    .catch(e => {
      if (alert) {
        alert.setter({ message: `${e.message}`, type: 'error', timeout: 0 })
      } else {
        error.value = e.message
      }
    })
    .finally(() => { loading.value = false })
  const input = computed(() => {
    const isLinked = (parent: string): boolean => {
      return parents.value?.find(p => p.name === parent)?.linked ?? false
    }

    /**
     * Gets the actual value or the linked value depending on linkage
     * @param field
     */
    const getValue = (field: GeneratedInputField): string | undefined => {
      const parent = field.parent
      const child = field.child
      const link = field.link ?? ''
      const value = field.value
      let x
      if (isLinked(parent)) {
        x = fields.value.find(f => f.parent === link && f.child === child)?.value ?? ''
      } else x = value
      if (field.field.toLowerCase().includes('phone')) x = x.replace(/-/g, '')
      return x || undefined
    }

    const x: {[key: string]: string | undefined} = {}
    for (const field of fields.value.filter(f => f.parent !== 'meta')) {
      x[field.field] = getValue(field)
    }
    return x as unknown as T
  })

  const ready = computed(() => {
    // we need to check
    // input.value[key] AND fields(where field.field = key).optional
    // do not check fields[n].value
    // prep
    const getField = (key: string): GeneratedInputField | false => {
      return fields.value.find(f => f.field === key) ?? false
    }
    const fieldIsReady = (key: string, value: string) => {
      const field = getField(key)
      if (!field) return false
      return value !== '' || field.optional
    }
    // logic
    for (const key in input.value) {
      // @ts-ignore I know this works
      if (!fieldIsReady(key, input.value[key])) return false
    }
    return true
  })

  return { fields, ready, error, input, loading, parents, filter: filteredFieldsByParent }
}
