































































import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import tAlert from '@/components/notifications/tAlert.vue'
import { computed, ComputedRef, defineComponent, provide, ref } from '@vue/composition-api'
import { ApolloClients } from '@vue/apollo-composable'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import {
  SupportedGeneratedInputTypes,
  SupportedGeneratedInputTypeStrings,
  useGeneratedInputs
} from '@/composition/UseGeneratedInputs'
import { useTAlert } from '@/composition/UseTAlert'
import { CapIt } from '@/lib/helpers'
import { Create__Shipping_Account__Input, Shipping_Account } from '@/models/generated/graphql/ErpBackend'
import { useCreateFedExOrUpsAccount, UseCreateFedexOrUpsAccountReturn } from '@/composition/UseCreateFedexOrUpsAccount'

export default defineComponent({
  name: 'NewFedExOrUPSEasypostAccount',
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    't-alert': tAlert
  },
  props: {
    carrier: {
      type: String as () => 'FedEx' | 'UPS',
      required: true
    },
    carrierId: {
      type: [Number, String],
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apollo
    })

    const { alert } = useTAlert('')
    const type: SupportedGeneratedInputTypeStrings = props.carrier === 'FedEx' ? 'EasyPost_RegisterFedexAccountInput' : 'EasyPost_RegisterUPSAccountInput'
    const { fields, input, filter, parents: groups, loading, ready } = useGeneratedInputs<SupportedGeneratedInputTypes>(type, alert)
    fields.value.push(
      {
        field: 'name',
        label: 'Account Name',
        placeholder: '',
        hint: 'Needs to be unique',
        optional: false,
        value: '',
        parent: 'meta',
        child: 'name',
        link: ''
      },
      {
        field: 'description',
        label: 'Description',
        placeholder: 'Account for Joe',
        hint: 'Optional',
        value: '',
        optional: true,
        parent: 'meta',
        child: 'description',
        link: ''
      })
    groups.value.push({ name: 'meta', link: '', linked: false })
    const success = ref(false)
    const tqsInput: ComputedRef<Create__Shipping_Account__Input> = computed(() => {
      const x: Create__Shipping_Account__Input = { number: '', shipper_id: `${props.carrierId}` }
      for (const field of fields.value) {
        switch (field.field) { // check field.field for specific cases to line up with tqsInput type
          case 'description':
            x.description = field.value || undefined
            break
          case 'account_number':
            x.number = field.value
            break
          case 'name':
            x.name = field.value
            break
          default:
            break
        }
      }
      return x
    })

    const { loading: creating, submit: create, onDone, onError }: UseCreateFedexOrUpsAccountReturn =
      useCreateFedExOrUpsAccount({ easypostInput: input, tqsInput: tqsInput })
    onDone((result: Shipping_Account) => {
      emit('success', { value: result })
      alert.setter({ message: 'Success', type: 'success' })
      success.value = true
    })
    onError((e: Error) => {
      alert.setter({ message: e.message, type: 'error' })
    })

    const submit = async () => {
      if (ready.value) {
        await create(props.carrier)
      }
    }

    return {
      emit,
      submitting: creating,
      submit,
      CapIt,
      alert,
      fields,
      input,
      tqsInput,
      filter,
      groups,
      loading,
      ready,
      success
    }
  }
})
