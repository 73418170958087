<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="services"
          :search="search"
          item-key="id"
          :rows-per-page-items="[5, 10, 15]"
          :loading="loading"
        >
          <template #no-data>
            <v-alert color="warning" v-if="!loading" :value="true">
              No services? Add one!
            </v-alert>
            <v-alert color="info" v-else icon="sync" :value="true">
              Loading your services, hold tight!
            </v-alert>
          </template>
          <template #items="{ item }">
            <td>{{ item.shipper.name }}</td>
            <td>{{ item.service }}</td>
            <td class="text-xs-left">
              <v-avatar size="16" :color="item.color" />
            </td>
            <td class="text-xs-right">
              <edit-button @click="editColor(item.id)"/>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-space-between align-end wrap>
          <v-flex shrink>
            <v-text-field
              v-model="search"
              label="Search..."
              hint="Carrier or Service"
              persistent-hint
              box
            />
          </v-flex>
          <v-flex xs12 pb-4>
            <v-layout justify-center align-center>
              <span class="bold primary--text">Services are automatically added when adding a carrier.</span>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showDialog" lazy width="80%">
      <v-card v-if="dialog === 'color'">
        <v-card-title class="brown">
          <h3 class="white--text">Edit Service Color</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout justify-center align-center>
              <swatches-color-picker v-model="color" />
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel()">Close</v-btn>
          <v-btn
            v-if="!savedColor"
            color="success"
            :loading="saving"
            :disabled="!color || saving"
            @click="saveColor"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GET_ALL_CARRIERS__ALIASED as CARRIERS } from '@/api/graphql/Constants/Shipments'
import { Swatches } from 'vue-color'
import {
  EDIT_SERVICE_COLOR,
  GET_SHIPPING_SERVICE_LEVELS
} from '@/api/graphql/Constants/Shipments'
import EditIconButton from '../buttons/EditIconButton.vue'
import { RefreshEvents } from '@/store/refreshEvents.ts'
export default {
  name: 'services',
  components: {
    'swatches-color-picker': Swatches,
    'edit-button': EditIconButton
  },
  computed: {
    showDialog () {
      return this.dialog !== ''
    }
  },
  watch: {
    showDialog (value) {
      if (value) {
      }
      this.$apollo.queries.carriers.refresh()
    }
  },
  data () {
    return {
      services: [],
      serviceLevels: [],
      search: '',
      headers: [
        { text: 'Carrier Name', value: 'shipper.name' },
        { text: 'Service Name', value: 'service' },
        { text: 'Color', value: 'color' },
        { text: 'Edit Color', value: 'edit' }
      ],

      carriers: [],
      loadingCarriers: false,

      color: {},
      savedColor: false,
      serviceIdToEdit: 0,

      loading: false,
      saving: false,

      dialog: '',

      // new service information
      serviceName: { text: '', value: '' },
      carrierId: 0,
      errorMessage: '',
      errorType: 'error'
    }
  },
  apollo: {
    services: {
      query: GET_SHIPPING_SERVICE_LEVELS,
      update: (data) => data.shipping_service_levels,
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    },
    carriers: {
      query: CARRIERS,
      watchLoading (isLoading) {
        this.loadingCarriers = isLoading
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    cancel () {
      this.dialog = ''
      this.errorMessage = ''
      this.errorType = 'info'
      this.carrierName = ''
      this.serviceName = ''
      this.carrierId = 0
      this.serviceIdToEdit = 0
      this.savedColor = false
      this.color = {}
      this.serviceLevels = []
    },

    editColor (id) {
      this.serviceIdToEdit = id
      this.dialog = 'color'
    },

    saveColor () {
      this.saving = true
      const hex = this.color.hex
      const idToSave = this.serviceIdToEdit
      this.$apollo
        .mutate({
          mutation: EDIT_SERVICE_COLOR,
          variables: {
            id: idToSave,
            color: hex
          }
        })
        .then(({ data: { service } }) => {
          const index = this.services.findIndex((s) => {
            return s.id === idToSave
          })
          this.services.splice(index, 1, service)
          this.saving = false
          this.savedColor = true
        })
    }
  },
  mounted () {
    RefreshEvents.$on('refresh-carrier-services', () => this.$apollo.queries.services.refresh())
  },
  beforeDestroy () {
    RefreshEvents.$off('refresh-carrier-services', () => this.$apollo.queries.services.refresh())
  }
}
</script>
