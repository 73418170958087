import { computed, ComputedRef, ref, Ref } from '@vue/composition-api'
import { EasypostCarrierType } from '@/composition/UseEasyPostCarrierInputs'
import { GetAllSavedCarrierNameAndIds } from '@/api/graphql/Constants/Shipments'
import { TAlertObject } from '@/composition/UseTAlert'
import { useEventHook } from '@/composition/UseEventHook'

interface IStoredEasypostDbCarrier {
  name: string,
  id: string,
  type: string
}

interface UseEasypostCarrierSelectReturn {
  loading: Ref<boolean>
  carriers: ComputedRef<IStoredEasypostDbCarrier[]>
  search: Ref<string>,
  error: Ref<string>,
  setCarrier: (param: IStoredEasypostDbCarrier) => void,
  onSetSuccess: (fn: (param: { type: EasypostCarrierType, id: string }) => void) => void
  onSetFailure: (fn: (param: Error) => void) => void
}

export function useEasypostCarrierSelect (alert: TAlertObject | undefined): UseEasypostCarrierSelectReturn {
  const loading = ref(false)
  const error: Ref<string> = ref('')
  const carrierTypes: EasypostCarrierType[] = require('../views/settings/carriers/carrierTypes.json')
  const carriersInDatabase: Ref<{ name: string, id: string }[]> = ref([])
  const carriersAvailableForPicking: ComputedRef<IStoredEasypostDbCarrier[]> = computed(() => {
    const returnables = []
    const carriers = carrierTypes.map(c => {
      const parsed = c.readable.toLowerCase().replace('account', '').replace(/\s/g, '')
      return {
        parsed: parsed,
        readable: c.readable
      }
    })
    for (const carrier of carriers) {
      const inDb = carriersInDatabase.value.find(c => c.name.toLowerCase() === carrier.parsed)
      if (inDb) {
        returnables.push({ ...inDb, type: carrier.readable })
      }
    }
    return returnables
  })
  const search = ref('')

  const carriers: ComputedRef<IStoredEasypostDbCarrier[]> = computed(() => {
    return carriersAvailableForPicking.value.filter(c => c.name.indexOf(search.value) > -1)
  })

  const onSetSuccess = useEventHook()
  const onSetFailure = useEventHook()

  const setCarrierType = (carrier: IStoredEasypostDbCarrier) => {
    const c: EasypostCarrierType | undefined = carrierTypes.find(c => c.readable === carrier.type)
    if (c) {
      onSetSuccess.trigger({ type: c, id: carrier.id })
    } else {
      onSetFailure.trigger(new Error('A carrier type could not be found'))
    }
  }

  GetAllSavedCarrierNameAndIds()
    .then(result => {
      carriersInDatabase.value = result
    })
    .catch(error => {
      if (alert?.setter) {
        alert.setter(error.message)
      } else {
        error.value = error.message
      }
    })
    .finally(() => {
      loading.value = false
    })

  return {
    carriers,
    search,
    loading,
    error,
    setCarrier: setCarrierType,
    onSetSuccess: onSetSuccess.on,
    onSetFailure: onSetFailure.on
  }
}
