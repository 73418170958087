<template>
  <v-btn small color="orange" icon flat @click="$emit('click')">
    <v-icon small>fal fa-pencil</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'EditIconButton'
}
</script>
