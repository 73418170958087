import { apolloClient as apollo } from '@/api/graphql/apollo'
import gql from 'graphql-tag'

/* QUERIES */

export const INTROSPECT_FOR_INPUT_FIELDS = gql`
  query IntrospectForFedexInput ($name: String!) {
    __type (name: $name) {
      name
      inputFields {
        name
        description
      }
    }
  }
`

interface IntrospectionInputFields {
  __type: {
    name: string,
    inputFields: Array<{
      name: string,
      description: string
    }>
  }
}

export async function GetInputFieldsAndDescriptions (name: string): Promise<Array<{ name: string, description: string }> | undefined> {
  try {
    const response = await apollo.query({ query: INTROSPECT_FOR_INPUT_FIELDS, variables: { name: name } })
    return response.data.__type.inputFields
  } catch (e) {
    throw new Error(e.message)
  }
}
