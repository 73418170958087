





































import { defineComponent } from '@vue/composition-api'
import CancelButton from '@/components/buttons/CancelButton.vue'
import { useTAlert } from '@/composition/UseTAlert'
import { useEasypostCarrierSelect } from '@/composition/UseEasypostCarriersSelect'
import { EasypostCarrierType } from '@/composition/UseEasyPostCarrierInputs'
import tAlert from '@/components/notifications/tAlert.vue'

export default defineComponent({
  name: 'EasypostCarrierSelect',
  components: {
    'cancel-button': CancelButton,
    't-alert': tAlert
  },
  setup (_, { emit }) {
    const { alert } = useTAlert('Carriers will only appear here if they have been added in the "Carriers" table')
    const { carriers, loading, search, setCarrier, onSetFailure, onSetSuccess } = useEasypostCarrierSelect(alert)
    onSetFailure(error => {
      alert.setter({ message: error.message, type: 'error' })
    })
    onSetSuccess(({ type, id }: { type: EasypostCarrierType, id: string }) => {
      emit('updated', { value: type, id: id })
    })

    return {
      alert,
      carriers,
      emit,
      loading,
      search,
      setCarrier
    }
  }
})
