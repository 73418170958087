






























































import { defineComponent, provide, ref } from '@vue/composition-api'
import { apolloClient as apollo } from '@/api/graphql/apollo'
import { EasypostCarrierType, useEasyPostCarrierInputs } from '@/composition/UseEasyPostCarrierInputs'
import { CapIt } from '@/lib/helpers'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import tAlert from '@/components/notifications/tAlert.vue'
import { useTAlert, UseTAlertReturn } from '@/composition/UseTAlert'
import { ApolloClients } from '@vue/apollo-composable'
import { Shipping_Carrier } from '@/models/generated/graphql/ErpBackend'
import { useCreateCompanyCarrierAccount } from '@/composition/UseCreateCompanyCarrierAccount'

export default defineComponent({
  name: 'EasypostCarrierFieldsInput',
  components: {
    'cancel-button': CancelButton,
    'submit-button': SubmitButton,
    't-alert': tAlert
  },
  props: {
    carrierType: {
      type: Object as () => EasypostCarrierType,
      required: true
    }
  },
  setup (props, { emit }) {
    provide(ApolloClients, {
      default: apollo
    })
    const { fields, groups, filter, input } = useEasyPostCarrierInputs(props.carrierType)
    // add meta fields
    fields.value.push(
      {
        label: 'Reference',
        value: '',
        field: 'reference',
        hint: 'Defined by you',
        visibility: 'visible',
        group: 'meta'
      },
      {
        label: 'Name',
        field: 'readable',
        hint: 'Defined by you',
        value: '',
        visibility: 'visible',
        group: 'meta'
      },
      {
        label: 'Is a duplicate',
        value: false,
        field: 'clone',
        hint: 'See our documentation for info (SOON)',
        visibility: 'checkbox',
        group: 'meta'
      }
    )
    groups.value.push({ name: 'meta', link: '', linked: false })

    const { alert }: UseTAlertReturn = useTAlert('')
    const success = ref(false)

    const {
      mutate,
      loading,
      onDone,
      onError
    } = useCreateCompanyCarrierAccount(input)
    onDone((result: Shipping_Carrier) => {
      if (result.name) {
        emit('success', { value: result })
        success.value = true
        alert.setter({ message: 'Success', type: 'success' })
      }
      loading.value = false
    })
    onError((error: Error) => {
      alert.setter({ message: error.message, type: 'error' })
      loading.value = false
    })

    const submit = () => {
      mutate()
    }

    return {
      emit,
      CapIt,
      fields,
      groups,
      filter,
      input,
      loading,
      submit,
      success,
      alert
    }
  }
})
